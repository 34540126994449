"use strict";
var portal = angular.module("portal");

/*****************
 SERVICES
 ******************/

portal.factory("userService", [
    "httpService",
    function (httpService) {
        var service = {};
        service.get = function (id) {
            return httpService.get("/api/user/" + id);
        };
        service.getReadonly = function (id, withDetails) {
            return httpService.get("/api/user/" + id + "/readonly/" + (withDetails ? 1 : 0));
        };
        service.getRole = function (id) {
            return httpService.get("/api/user/role/" + id);
        };
        service.create = function (user) {
            return httpService.put("/api/user/", user);
        };
        service.update = function (user) {
            return httpService.post("/api/user/", user);
        };
        service.updateProductCommunication = function (id, productCommunication) {
            return httpService.patch("/api/user/productcommunication", {
                Id: id,
                ProductCommunication: productCommunication,
            });
        };
        service.reset = function (record) {
            return httpService.post("/api/user/reset", record);
        };
        service.forgot = function (userName) {
            return httpService.post("/api/user/forgot", { userName: userName });
        };
        service.restore = function (code, password, passwordConfirm) {
            return httpService.post("/api/user/restore", {
                code: code,
                password: password,
                passwordConfirm: passwordConfirm,
            });
        };
        service.find = function (clientId) {
            // todo [as]: check if it should be post
            return httpService.post("/api/user/find/", { clientId: clientId });
        };
        service.search = function (clientId, search, onlyCustodian) {
            return httpService.post("/api/user/search/", {
                clientId: clientId,
                search: search,
                onlyCustodian: onlyCustodian,
            });
        };
        service.notifications = function () {
            return httpService.get("/api/user/notifications/list");
        };
        service.notificationsAvailable = function () {
            return httpService.get("/api/user/notifications/list-available");
        };
        service.markNotifications = function (NotificationIds, ReadTime, Status) {
            return httpService.patch("/api/user/mark-user-notifications", {
                NotificationIds: NotificationIds,
                ReadTime: ReadTime,
                Status: Status,
            });
        };
        service.dashboardSummary = function (dateFrom, dateTo, enforce) {
            return httpService.post("/api/dashboard/summary" + (enforce ? "?resetCache=" + new Date().getTime() : ""), {
                dateFrom,
                dateTo,
            });
        };
        service.dashboardSummaryTransaction = function (dateFrom, dateTo, enforce) {
            return httpService.post(
                "/api/dashboard/summary-transaction" + (enforce ? "?resetCache=" + new Date().getTime() : ""),
                {
                    dateFrom,
                    dateTo,
                }
            );
        };
        service.userCount = function (enforce) {
            return httpService.get("/api/dashboard/userCount" + (enforce ? "?resetCache=" + new Date().getTime() : ""));
        };
        service.disableTfa = function (id) {
            return httpService.delete("/api/user/" + id + "/tfa");
        };
        service.logoff = function (id) {
            return httpService.get("/api/user/" + id + "/logoff");
        };
        service.logout = function () {
            return httpService.get("/logout");
        };
        return service;
    },
]);
