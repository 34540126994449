"use strict";
var portal = angular.module("portal");

var isCurrent = function (url, $location) {
    var current = false;
    var path = $location.path();
    if (path !== "/") {
        //console.log(path + " vs " + url + " - " + path.indexOf(url));
        current = path.indexOf(url) === 0;
    }
    return current;
};

var setActive = function (l, $location) {
    if (isCurrent(l._url || l.url, $location)) {
        l.class = "active";
    } else {
        l.class = "";
    }
};

var hasAccess = function (m, authService) {
    return (
        authService.isAuthenticated() &&
        (typeof m.roles === "undefined" || _.includes(m.roles, authService.principal.roleId)) &&
        (typeof m.claim === "undefined" || authService.principal.claims[m.claim])
    );
};

portal.directive("daastopmenu", [
    "$rootScope",
    "$location",
    "$route",
    "authService",
    "MENU",
    "$injector",
    "coreService",
    function ($rootScope, $location, $route, authService, MENU, $injector, core) {
        return {
            restrict: "E",
            templateUrl: "/partials/include/topmenu.html",
            link: function (scope, element, attrs) {
                var selectCurrent = function () {
                    _.forEach(scope.leaves, function (l) {
                        setActive(l, $location);
                    });
                };

                if (!$rootScope.notificationCountNo) {
                    core.user.notifications().then(function (n) {
                        $rootScope.notificationCountNo = n.length;
                    });
                }

                var leaves = [];
                _.forEach(MENU.menus, function (m, index) {
                    if (!m.hideInTopMenu && hasAccess(m, authService)) {
                        if (!m.url) {
                            // find nested url if not direct
                            var leaf = _.find(m.leaves, function (l) {
                                return hasAccess(l, authService);
                            });
                            m.url = leaf.url;
                        }

                        if (typeof m.show === "function") {
                            m.index = leaves.length; // remember index
                            // todo [ds]: resolve core using injector
                            // directive created on start when no core exist
                            //
                            var core = $injector.get("coreService");
                            m.show(core).then(function (s) {
                                if (s) {
                                    setActive(m, $location);
                                    leaves.splice(m.index, 0, m); //leaves.push(m);
                                }
                            });
                        } else {
                            setActive(m, $location);
                            leaves.push(m);
                        }
                    }
                });

                scope.$on("$routeChangeSuccess", function (next, current) {
                    if (current.loadedTemplateUrl) {
                        // don't call it twise
                        selectCurrent();
                    }
                });

                scope.leaves = leaves;

                scope.principal = authService.principal;
            },
        };
    },
]);

portal.directive("daasleftmenu", [
    "$location",
    "$route",
    "authService",
    "MENU",
    function ($location, $route, authService, MENU) {
        return {
            restrict: "E",
            templateUrl: "/partials/include/leftmenu.html",
            scope: {
                key: "@",
                caption: "@",
                showLeftMenu: "=?",
            },
            link: function (scope, element, attrs) {
                var menu = _.find(MENU.menus, function (m) {
                    return m.key === scope.key;
                });

                var leaves = [];
                _.forEach(menu.leaves, function (l) {
                    if (hasAccess(l, authService)) {
                        setActive(l, $location);
                        leaves.push(l);
                    }
                });

                scope.leaves = leaves;

                scope.showLeftMenu = leaves.length > 1;
            },
        };
    },
]);

portal.directive("daasmanage", function () {
    return {
        restrict: "E",
        transclude: true,
        templateUrl: "/partials/include/manage.html",
    };
});

portal.directive("daasreports", function () {
    return {
        restrict: "E",
        transclude: true,
        templateUrl: "/partials/include/reports.html",
    };
});

portal.directive("daastable", [
    "$rootScope",
    "uiService",
    "$compile",
    "moment",
    function ($rootScope, uiService, $compile, moment) {
        return {
            link: function (scope, element, attrs) {
                var options = scope.$eval(attrs.daastable);
                if (!options) {
                    throw new Error("Controller should contain DataTable config object: " + attrs.daastable);
                }

                // the order matters
                options = _.merge({ scrollX: true, useStoredPageLength: true }, options);

                if (options.useStoredPageLength && !!window.localStorage && typeof Storage !== "undefined") {
                    var defaultLength = window.localStorage.getItem("settings.defaults.list.pageLength") || 25;
                    var key = options.key || attrs.id || attrs.name;
                    var pageLengthKey = "settings." + key + ".list.pageLength";
                    var length = window.localStorage.getItem(pageLengthKey) || defaultLength;
                    options = _.merge(options, { pageLength: length });

                    element.on("length.dt", function (e, settings, len) {
                        if (len == defaultLength) {
                            window.localStorage.removeItem(pageLengthKey);
                        } else {
                            window.localStorage.setItem(pageLengthKey, len);
                        }
                    });
                }

                if (options.showTableTools) {
                    var pdfMessage = "";
                    if (typeof options.showTableTools === "function") {
                        pdfMessage = options.showTableTools();
                    } else {
                        pdfMessage = options.showTableTools;
                    }

                    var file = "export." + moment().format("YYYY.MM.DD");
                    var ttDefaults = {
                        //dom: '<"clear">Blfrtip',
                        buttons: [
                            {
                                filename: file,
                                extend: "pdfHtml5",
                                className: "btn-sm",
                                message: pdfMessage,
                                orientation: "landscape",
                                pageSize: "LEGAL",
                                exportOptions: {
                                    modifier: {
                                        page: "current",
                                    },
                                },
                                customize: function (e) {
                                    // align header left
                                    e.styles.tableHeader.alignment = "left";
                                    if (typeof options.getPdfMessage === "function") {
                                        e.content[1].text = options.getPdfMessage();
                                    }
                                },
                            },
                            {
                                filename: file,
                                extend: "csvHtml5",
                                className: "btn-sm",
                                header: true,
                            },
                        ],
                    };
                    options = _.merge(options, ttDefaults);
                }

                var gridContainer = { grid: null };
                if (options.ajax && typeof options.ajax === "object") {
                    options.ajax.type = options.ajax.type || "POST";
                    options.ajax.error =
                        options.ajax.error ||
                        uiService.showAjaxGridErrorFactory(options.ajax.type, $rootScope, function () {
                            return gridContainer.grid;
                        });
                }

                var eventedElement = element;
                if (options.xhr_dt) {
                    element = element.on("xhr.dt", function (e, settings, json) {
                        options.xhr_dt(settings, json);
                    });
                }

                var dt = eventedElement.DataTable(options);
                gridContainer.grid = dt;

                if (options.createLink) {
                    var createButtonText = "Create " + $(location).attr("href").split("/").pop().replace(/s$/, "");
                    var createLinkHtml =
                        '<a class="btn btn-sm btn-primary text-capitalize" href="' +
                        options.createLink +
                        '">' +
                        createButtonText +
                        "</a>";
                    if (
                        options.createExtraLinks &&
                        _.some(options.createExtraLinks, function (l) {
                            return !l.hidden;
                        })
                    ) {
                        createLinkHtml =
                            '<div class="btn-group">' +
                            createLinkHtml +
                            '<button type="button" class="btn btn-sm btn-primary dropdown-toggle text-capitalize" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">' +
                            '<span class="caret"></span>' +
                            '<span class="sr-only">Toggle Dropdown</span>' +
                            "</button>";
                        createLinkHtml += '<ul class="dropdown-menu">';
                        for (var i = 0; i < options.createExtraLinks.length; i++) {
                            var extraLink = options.createExtraLinks[i];
                            if (!extraLink.hidden) {
                                if (extraLink.link) {
                                    createLinkHtml +=
                                        '<li><a href="' + extraLink.link + '">' + extraLink.name + "</a></li>";
                                } else {
                                    var classname = extraLink.controllerClassname || "main";
                                    var key = extraLink.key;
                                    createLinkHtml +=
                                        '<li><a href="' +
                                        "javascript:angular.element(document.getElementsByClassName('" +
                                        classname +
                                        "')[0]).scope().onExtraLinkClick('" +
                                        key +
                                        "')\">" +
                                        extraLink.name +
                                        "</a></li>";
                                }
                            }
                        }

                        createLinkHtml += "</ul>";
                        createLinkHtml += "</div>";
                    }

                    createLinkHtml += "&nbsp;";

                    if ($(".form-inline").length > 0) {
                        element.parents("div.ng-scope").find(".form-inline").append(createLinkHtml);
                    } else {
                        element
                            .parents("div.ng-scope")
                            .find(".dataTables_length")
                            .append("&nbsp;" + createLinkHtml);
                    }
                } else if (options.createButton) {
                    var btn = $(
                        '<a class="btn btn-sm btn-primary text-capitalize" href="javascript:void(0)" ng-click="' +
                            options.createButton +
                            '">Create</a>'
                    );
                    $compile(btn)(scope);
                    element.parents("div.dataTables_wrapper").find(".toolbar").append(btn).append("&nbsp;");
                }
                if (options.returnLink) {
                    element
                        .parents("div.dataTables_wrapper")
                        .find(".toolbar")
                        .append('<a class="btn btn-sm btn-default" href="' + options.returnLink + '">Return</a>&nbsp;');
                }
                if (options.reloadEvent) {
                    scope._handlers = scope._handlers || {};
                    scope._handlers[options.reloadEvent] = $rootScope.$on(options.reloadEvent, function () {
                        dt.ajax.reload();
                    });
                }

                if (options.rowClick) {
                    element.on("click", "tbody tr", function (event) {
                        var data = dt.row(this).data();
                        var cb = scope.$eval(options.rowClick);
                        if (data && cb) {
                            cb(data);
                        }
                    });
                }

                // this hasn't been tested yet
                if (options.source) {
                    scope.$watch(options.source, function (value) {
                        var val = value || null;
                        if (val) {
                            dt.fnClearTable();
                            var d = scope.$eval(options.source);
                            dt.fnAddData(d);
                        }
                    });
                }

                // remove on handler which duplicates events every time you get back to list
                scope.$on("$destroy", function () {
                    if (scope._handlers && scope._handlers[options.reloadEvent]) {
                        scope._handlers[options.reloadEvent]();
                        scope._handlers[options.reloadEvent] = null;
                    }
                });
            },
        };
    },
]);

portal.directive("daasvalidsummary", function () {
    return {
        restrict: "E",
        scope: {
            key: "@?key",
        },
        template:
            '<div class="validation-summary alert alert-danger" ng-show="errors && errors.length">' +
            '<div ng-repeat="err in errors">' +
            '<span class="error-title" ng-if="err.extra.title || err.path[0]">{{err.extra.title || err.path[0]}}&nbsp;:&nbsp;</span><span class="error-description">{{err.description}}</span>' +
            "</div>" +
            "</div>",
        link: function (scope, element, attrs) {
            scope.$parent.$watch("errors", function (value) {
                // [as] if form has multiple summary we should specify key attribute and set this key at parent scope in order error to be shown
                if (!scope.$parent.errorsKey || !scope.key || scope.$parent.errorsKey === scope.key) {
                    scope.errors = value;
                }
            });
        },
    };
});

/// User
portal.directive("daasuser", function () {
    return {
        restrict: "E",
        templateUrl: "/partials/include/user.details.html",
        scope: {
            user: "=source",
            title: "@title",
            formref: "=",
            canBeAccount: "=?canBeAccount",
        },
    };
});

/// Date
portal.directive("daasdate", function () {
    return {
        restrict: "E",
        templateUrl: "/partials/include/date.html",
        scope: {
            dt: "=source",
            id: "@key",
            disabled: "=ngDisabled",
            initDate: "@initDate",
            class: "@class",
            initTime: "@initTime",
            minDate: "=?minDate",
            onlyDate: "=?onlyDate",
        },

        controller: [
            "$scope",
            function ($scope) {
                $scope.format = $scope.onlyDate ? "MM/dd/yyyy" : "MM/dd/yyyy HH:mm:ss";
                $scope.opened = false;
                $scope.initialTimeSet = false;
                // [as] can't make init date works correctly
                // $scope.initDate = $scope.initDate === 'today' ? new Date(): new Date($scope.initDate);
                $scope.dateOptions = {
                    formatYear: "yy",
                    startingDay: 1,
                    minDate: $scope.minDate
                        ? $scope.minDate == "now"
                            ? moment().toDate()
                            : moment($scope.minDate).toDate()
                        : undefined,
                };

                $scope.open = function ($event) {
                    $event.preventDefault();
                    $event.stopPropagation();
                    $scope.opened = true;
                };

                $scope.$watch("minDate", function (newVal) {
                    if (!_.isNil(newVal)) {
                        $scope.dateOptions.minDate = newVal;
                        $scope.initialTimeSet = false;
                    }
                });

                $scope.$watch("dt", function (v) {
                    if (v) {
                        if (!$scope.initialTimeSet) {
                            var value = moment(v);
                            //console.log('current date value: ', value.format());
                            var time = $scope.initTime ? $scope.initTime : $scope.onlyDate ? "00:00:00" : "12:00:00";
                            var t = moment(time, "HH:mm:ss");
                            //console.log('initial time value: ', t.format());
                            value.hours(t.hours()).minutes(t.minutes()).seconds(t.seconds());
                            //console.log('result value: ', value.format());
                            $scope.initialTimeSet = true;
                            $scope.dt = value.toDate();
                        }
                    } else if (_.isNull(v)) {
                        // when user clears date it because null, when user type invalid date it's undefined
                        $scope.initialTimeSet = false;
                    }
                });
            },
        ],
    };
});

/// User select
portal.directive("daasuserselect", function () {
    return {
        restrict: "E",
        templateUrl: "/partials/include/user.select.html",
        scope: {
            id: "@?id",
            title: "@title",
            clientId: "=clientId",
            userId: "=userId",
            disabled: "=?ngDisabled",
            onlyCustodian: "=onlyCustodian",
            required: "=",
        },
        controller: [
            "$scope",
            "coreService",
            function ($scope, core) {
                $scope.formatLabel = core.ui.formatUser;

                $scope.isRequired = typeof $scope.required === "undefined" ? true : $scope.required;

                $scope.user = {};
                $scope.users = [];

                $scope.getUsers = function (val) {
                    if ($scope.clientId) {
                        core.user.search($scope.clientId, val, $scope.onlyCustodian).then(function (u) {
                            $scope.users = u;
                        });
                    } else {
                        $scope.userId = null;
                        $scope.users = [];
                    }
                };

                $scope.reload = function (value, prev) {
                    if (prev && value !== prev) {
                        $scope.clear();
                    }
                    if (value) {
                        $scope.getUsers();
                    }
                };

                $scope.select = function ($item, $model, $label) {
                    if ($item) {
                        $scope.userId = $item.Id;
                    } else {
                        $scope.userId = undefined;
                    }
                };

                $scope.clear = function () {
                    $scope.userId = undefined;
                    $scope.user.selected = undefined;
                };

                // when selected
                var _loadInitial = function () {
                    if ($scope.userId && !$scope.user.selected) {
                        core.user.getReadonly($scope.userId).then(function (u) {
                            $scope.user.selected = u;
                        });
                    }
                };

                $scope.$watch("userId", function (value) {
                    _loadInitial();
                });

                $scope.$watch("clientId", $scope.reload);
            },
        ],
    };
});

/// SAVE
// [as] i wasn't able to make directive to use common button and wrap saving - use this for now
portal.directive("daasbutton", function () {
    return {
        restrict: "E",
        templateUrl: "/partials/include/button.html",
        scope: {
            title: "@title",
            broadcastonly: "@broadcastonly", // property required to link daasbutton with daasform
            broadcastname: "@broadcastname",
            action: "=action",
            disabled: "=ngDisabled",
            visible: "=?ngVisible",
        },
        controller: [
            "$scope",
            function ($scope) {
                $scope.title = typeof $scope.title === "undefined" ? "Save" : $scope.title;
                $scope.visible = typeof $scope.visible === "undefined" ? true : $scope.visible;
                $scope.loading = false;
                $scope.click = function ($event) {
                    if (!$scope.broadcastonly) {
                        $event.preventDefault();
                        $scope.performAction();
                    }
                };
                $scope.performAction = function () {
                    $scope.loading = true;
                    var res = $scope.action();
                    if (res && typeof res.finally === "function") {
                        res.finally(_enable);
                    } else {
                        //console.warn('acting is expecting to be deferred');
                        _enable();
                    }
                };

                var _enable = function () {
                    $scope.loading = false;
                };
            },
        ],
        link: function ($scope, $element, $attributes) {
            if (!$scope.broadcastonly) {
                var $parentForm = $element.parents("form");
                if (
                    $parentForm.length === 1 &&
                    $parentForm.attr("daasform") !== undefined &&
                    $parentForm.attr("name") !== undefined
                ) {
                    $scope.broadcastonly = true;
                    $scope.broadcastname = $parentForm.attr("name");

                    $element.attr("broadcastonly", $scope.broadcastonly);
                    $element.attr("broadcastname", $scope.broadcastname);
                    $attributes.broadcastonly = $scope.broadcastonly;
                    $attributes.broadcastname = $scope.broadcastname;
                }
            }

            if ($scope.broadcastonly) {
                $scope.$on("daasbutton-action-" + $scope.broadcastname, function () {
                    $scope.performAction();
                });
            }
        },
    };
});

/// Form
portal.directive("daasform", [
    "$compile",
    "$parse",
    "$timeout",
    "HTML_AUTOCOMPLETE_DEFAULT_MODE",
    "uiService",
    function ($compile, $parse, $timeout, autocompleteMode, uiService) {
        return {
            restrict: "A",
            link: function (scope, element, attributes) {
                $timeout(function () {
                    // hint:
                    // portal.run : $http.get('/partials/include/user.details.html', { cache: $templateCache });
                    // will help to fix an issue with Cntrl+F5

                    // Get form name
                    // (it is required for angular validation)
                    var formName = element.attr("name");
                    if (!formName) {
                        // todo [ds] i am unable to add it dynamically
                        throw new Error("Form does not contain name");
                        /*formName = 'frm';
                     element.attr('name', formName);
                     $compile(element[0])(scope);
                     return;*/
                    }

                    // Disable native html 5 validation
                    // (we have bootstrap styling and angular logic)
                    element.attr("novalidate", "novalidate");

                    // Disable autocomplete
                    element.attr("autocomplete", autocompleteMode);

                    // Add $submitted variable manipulation while form submitting
                    // (set $submitted=true when user submits the form and form is invalid)
                    var fn = null;
                    if (attributes.daasform) {
                        // f.e. <form name="frm" daasform="save()" ...
                        fn = $parse(attributes.daasform);
                    } else {
                        // f.e. <form name="frm" ...> ... <daasbutton ...>
                        fn = function () {
                            scope.$broadcast("daasbutton-action-" + formName);
                        };
                    }

                    var formController = element.controller("form");
                    formController.$submitted = false;
                    element.on("submit", function (event) {
                        // clear prev errors on submit
                        if (scope.errors) {
                            scope.errors.splice(0, scope.errors.length);
                        }

                        scope.$apply(function () {
                            formController.$submitted = true;
                            if (formController.$valid) {
                                fn(scope, { $event: event });
                                formController.$submitted = false;
                            }
                        });
                    });

                    // Add smart ng-disabled to input[type=submit]
                    // (disable button when user click it once and form is invalid)
                    // we are not using std submit button
                    /*var $submitElement = $(element).find('input[type=submit]');
                 if($submitElement.length > 0) {
                 var submitDisabledExpression = '(' + formName + '.$submitted && ' + formName + '.$invalid)';
                 if(daasbuttons.attr('ng-disabled')){
                 submitDisabledExpression = $submitElement.attr('ng-disabled') + ' || ' + submitDisabledExpression;
                 }
                 $submitElement.attr('ng-disabled', submitDisabledExpression);
                 $compile($submitElement[0])(scope);
                 }*/

                    // Add smart ng-disabled to daasbutton
                    // (disable button when user click it once and form is invalid)
                    /*var daasbuttons = $(element).find('daasbutton');
                 if(daasbuttons.length === 1 && !daasbuttons.attr('broadcastonly')){
                 daasbuttons.attr('broadcastonly', true);
                 daasbuttons.attr('broadcastname', formName);

                 var daasbuttonDisabledExpression = '(' + formName + '.$submitted && ' + formName + '.$invalid)';
                 if(daasbuttons.attr('ng-disabled')){
                 daasbuttonDisabledExpression = daasbuttons.attr('ng-disabled') + ' || ' + daasbuttonDisabledExpression;
                 }
                 daasbuttons.attr('ng-disabled', daasbuttonDisabledExpression);

                 // we will have two daasbutton controllers ((
                 $compile(daasbuttons)(scope);

                 }*/

                    // Fix markup
                    element.find(".form-group").each(function () {
                        var formGroup = $(this);
                        // prepare form group for feedback icons
                        formGroup.addClass("has-feedback");

                        var inputs = formGroup.find("input[ng-model],textarea[ng-model],select[ng-model]");
                        if (inputs.length > 0) {
                            inputs.each(function () {
                                var input = $(this);
                                /*
                             // todo [ds] it does not work as expected. it breaks input[type]=email control :((((
                             // each control should have a name to be able play with angular validation
                             // (warn: when name not defined (on input or/and form level)
                             // in the markup success feedback icon
                             // shown only after validation error :( )
                             if(!input.attr('name') && input.attr('id')){
                             input.attr('name', input.attr('id'));
                             $compile(input[0])(scope);
                             }*/

                                // our email validator already not allowed to use tags because of email regular validator
                                // so we will skip $compile, otherwise $compile call will break email entering
                                // $compile on email field resets value when users types first symbol after dot (((
                                /*if((input.is('[type=text]') || input.is('textarea')) && input.is(':visible') && input.attr('daas-no-script-tag')=== undefined && input.attr('daas-strict-email')=== undefined){
                             input.attr('daas-no-script-tag', '');
                             $compile(input)(scope);
                             }*/

                                // don't allow < symbol
                                input.on("keypress", function (e) {
                                    if (e.which === 60) {
                                        e.preventDefault();
                                    }
                                });
                                var CLOSE_TAG_REGEXP = /[<>]/;
                                input.on("paste", function (e) {
                                    var val = e.originalEvent.clipboardData.getData("text");
                                    var hasTags = CLOSE_TAG_REGEXP.test(val);
                                    if (hasTags) {
                                        e.preventDefault();
                                        uiService.error("Symbols '<' and '>' are not allowed");
                                    }
                                });

                                // map angular styles to corresponding bootstrap styles
                                scope.$watch(
                                    function () {
                                        return input.hasClass("ng-invalid") && !input.hasClass("ng-pristine");
                                    },
                                    function (isInvalid) {
                                        formGroup.toggleClass("has-error", isInvalid);
                                    }
                                );
                                scope.$watch(
                                    function () {
                                        return input.hasClass("ng-valid") && !input.hasClass("ng-pristine");
                                    },
                                    function (isInvalid) {
                                        formGroup.toggleClass("has-success", isInvalid);
                                    }
                                );

                                var inputName = input.attr("name");
                                if (!inputName && console && console.warn) {
                                    // todo [as] comment this warning because autocomplete has hidden input and we have bunch of warning - consider how to fix that
                                    // console.warn('control #' + input.attr('id') + ' should have a name');
                                    return;
                                }

                                var requiresFeedbackControl = input.attr("type") !== "radio";

                                // add validation messages

                                // no tags
                                if (
                                    formGroup.find('daasvalidationmessage[controlref="' + inputName + '"][noTags]')
                                        .length === 0
                                ) {
                                    var tagsAlertCntrl = $(
                                        '<daasvalidationmessage noTags="1" formref="' +
                                            formName +
                                            '" controlref="' +
                                            input.attr("name") +
                                            '" errortype="noTag" message="HTML is not allowed" />'
                                    ).insertAfter(input);
                                    $compile(tagsAlertCntrl[0])(scope);
                                }

                                // required
                                if (
                                    (input.attr("required") || input.attr("ng-required")) &&
                                    formGroup.find('daasrequiredmessage[controlref="' + inputName + '"]').length === 0
                                ) {
                                    var requiredAlertCntrl = $(
                                        '<daasrequiredmessage formref="' +
                                            formName +
                                            '" controlref="' +
                                            inputName +
                                            '" />'
                                    ).insertAfter(input);
                                    $compile(requiredAlertCntrl[0])(scope);

                                    formGroup.find('label[for="' + inputName + '"]').addClass("required");

                                    requiresFeedbackControl = false;
                                }

                                if (
                                    input.attr("type") === "email" &&
                                    formGroup.find('daasemailmessage[controlref="' + inputName + '"]').length === 0
                                ) {
                                    var emailAlertCntrl = $(
                                        '<daasemailmessage formref="' + formName + '" controlref="' + inputName + '" />'
                                    ).insertAfter(input);
                                    $compile(emailAlertCntrl[0])(scope);
                                }

                                // add feedback control
                                if (
                                    requiresFeedbackControl &&
                                    input.attr("nofeedback") != "true" &&
                                    formGroup.find(
                                        'daascontrolfeedback[controlref="' +
                                            inputName +
                                            '"], ' +
                                            'daasrequiredmessage[controlref="' +
                                            inputName +
                                            '"][nofeedback!=true]'
                                    ).length === 0
                                ) {
                                    var feedback = $(
                                        '<daascontrolfeedback formref="' +
                                            formName +
                                            '" controlref="' +
                                            inputName +
                                            '" />'
                                    ).insertAfter(input);
                                    $compile(feedback[0])(scope);
                                }
                            });
                        }
                    });

                    if (!attributes.norequiredhint) {
                        // add * required explanation
                        element.append('<div class="small required-info">* indicates required entry</div>');
                    }
                });
            },
        };
    },
]);

/// daascontrolfeedback
portal.directive("daascontrolfeedback", function () {
    return {
        restrict: "E",
        templateUrl: "/partials/include/controlfeedback.html",
        scope: {
            formref: "=",
            controlref: "@",
        },
    };
});

/// daasrequiredmessage
portal.directive("daasrequiredmessage", function () {
    return {
        restrict: "E",
        templateUrl: "/partials/include/requiredmessage.html",
        scope: {
            formref: "=",
            controlref: "@",
            nofeedback: "@",
        },
    };
});

/// daasemailmessage
portal.directive("daasemailmessage", function () {
    return {
        restrict: "E",
        templateUrl: "/partials/include/emailmessage.html",
        scope: {
            formref: "=",
            controlref: "@",
        },
    };
});

/// daasphonemessage
portal.directive("daasphonemessage", function () {
    return {
        restrict: "E",
        templateUrl: "/partials/include/phonemessage.html",
        scope: {
            formref: "=",
            controlref: "@",
        },
    };
});

/// daasvalidationmessage
portal.directive("daasvalidationmessage", function () {
    return {
        restrict: "E",
        templateUrl: "/partials/include/validationmessage.html",
        scope: {
            formref: "=",
            controlref: "@",
            errortype: "@",
            message: "@",
        },
    };
});

/// daasStrictEmail
portal.directive("daasStrictEmail", function () {
    var EMAIL_REGEXP =
        /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)+$/i;

    return {
        priority: 1,
        require: "ngModel",
        restrict: "",
        link: function (scope, elm, attrs, ctrl) {
            if (ctrl) {
                var strictEmailValidator = function (value) {
                    var validity = ctrl.$isEmpty(value) || EMAIL_REGEXP.test(value);
                    ctrl.$setValidity("email", validity);
                    return validity ? value : undefined;
                };
                ctrl.$formatters.push(strictEmailValidator);
                ctrl.$parsers.push(strictEmailValidator);
            }
        },
    };
});

/// daasStrictPhone
portal.directive("daasStrictPhone", function () {
    var PHONE_REGEXP = /^((\(\d{3}\)\s?)|(\d{3}\s?-?\s?))?\d{3}\s?-?\s?\d{2}\s?-?\s?\d{2}\s?$/i;

    return {
        priority: 1,
        require: "ngModel",
        restrict: "",
        link: function (scope, elm, attrs, ctrl) {
            if (ctrl) {
                var strictPhoneValidator = function (value) {
                    var validity = ctrl.$isEmpty(value) || PHONE_REGEXP.test(value);
                    ctrl.$setValidity("phone", validity);
                    return validity ? value : undefined;
                };
                ctrl.$formatters.push(strictPhoneValidator);
                ctrl.$parsers.push(strictPhoneValidator);
            }
        },
    };
});

portal.directive("daasGreaterOrEqualThan", function () {
    return {
        priority: 1,
        require: "ngModel",
        restrict: "",
        link: function (scope, elm, attrs, ctrl) {
            scope.$watch(attrs.daasGreaterOrEqualThan, function (value) {
                if (value) {
                    ctrl.$setViewValue(ctrl.$viewValue);
                }
            });

            if (ctrl) {
                var validate = function (value) {
                    var validity = ctrl.$isEmpty(value) || value >= scope.$eval(attrs.daasGreaterOrEqualThan);
                    ctrl.$setValidity("daas-greater-or-equal-than", validity);
                    var maxDistance = scope.$eval(attrs.daasGreaterOrEqualThanDistance);
                    if (maxDistance) {
                        validity =
                            ctrl.$isEmpty(value) || value - scope.$eval(attrs.daasGreaterOrEqualThan) <= maxDistance;
                        ctrl.$setValidity("daas-greater-or-equal-than-distance", validity);
                    }

                    return validity ? value : undefined;
                };
                ctrl.$formatters.push(validate);
                ctrl.$parsers.push(validate);
            }
        },
    };
});

portal.directive("daasNoScriptTag", function () {
    var TAG_REGEXP = /<(.|\n)*?>/;

    return {
        priority: 10,
        require: "ngModel",
        restrict: "",
        link: function (scope, elm, attrs, ctrl) {
            if (ctrl) {
                var noScriptTagValidator = function (value) {
                    var validity = !TAG_REGEXP.test(value);
                    ctrl.$setValidity("noTag", validity);
                    return validity ? value : undefined;
                };
                ctrl.$formatters.push(noScriptTagValidator);
                //                ctrl.$parsers.push(noScriptTagValidator);
            }
        },
    };
});

/// filter
portal.directive("daasfilter", function () {
    return {
        restrict: "E",
        templateUrl: "/partials/include/filter.html",
        scope: {
            allowNoPartner: "=?allowNoPartner",
            onlyPartner: "=?onlyPartner",
            filter: "=?filter",
            onChange: "=onChange",
        },
        controller: [
            "$scope",
            "coreService",
            "$uibModal",
            "$q",
            function ($scope, core, $modal, $q) {
                $scope.partners = $scope.clients = undefined;
                $scope.filter = $scope.$parent.filter = core.filter;

                $scope.showPartners = core.auth.isSystemAnyUser() || core.auth.isPartnerAnyUser();
                $scope.showClients = core.auth.isSystemAnyUser() || core.auth.isPartnerAnyUser();

                if (core.auth.isClientAnyUser()) {
                    $scope.filter.client = core.auth.principal.clientId;
                } else if (core.auth.isPartnerAnyUser()) {
                    // $scope.filter.partner = core.auth.principal.partnerId;
                }

                $scope.changePartner = function () {
                    if ($scope.filter.partner) {
                        $scope.filter.client = null;
                    }

                    _loadClients();
                    $scope.reload();
                };

                $scope.changeClient = function () {
                    $scope.reload();
                };

                $scope._clear = function () {
                    $scope.filter.partner = null;
                    $scope.filter.client = null;
                    $scope.clients = undefined;
                    $scope.reload();
                };

                $scope.clearPartner = function () {
                    $scope._clear();
                };
                $scope.clearClient = function () {
                    $scope.filter.client = null;
                    $scope.reload();
                };

                $scope.reload = function () {
                    if (typeof $scope.onChange === "function") {
                        $scope.onChange($scope.filter);
                    }
                };

                var _loadClients = function () {
                    var loadClients =
                        (!$scope.onlyPartner && $scope.filter.partner && $scope.filter.partner !== -1) ||
                        core.auth.isPartnerAnyUser();
                    if (loadClients) {
                        core.client
                            .findByPartner($scope.filter.partner ? $scope.filter.partner : -1)
                            .then(function (c) {
                                $scope.clients = c;
                                $scope.$broadcast("clients-loaded");
                            });
                    } else {
                        $scope.clients = undefined;
                    }
                };

                (function () {
                    if ($scope.showClients) {
                        _loadClients();
                    }
                })();
            },
        ],
    };
});

// dashboard summary item
portal.directive("daasdashboarditem", function () {
    return {
        restrict: "E",
        templateUrl: "/partials/include/dashboard.item.html",
        scope: {
            title: "@",
            data: "=",
            css: "@",
            class: "@",
        },
        controller: [
            "$scope",
            "coreService",
            function ($scope) {
                $scope.display = "?";
                $scope.$watch("data", function (value) {
                    if (typeof value !== "undefined") {
                        $scope.display = value;
                    } else {
                        $scope.display = "";
                    }
                });
            },
        ],
    };
});

// fileread
portal.directive("fileread", function () {
    return {
        scope: {
            fileread: "=",
        },
        link: function (scope, element, attributes) {
            element.bind("change", function (changeEvent) {
                var readers = [],
                    files = changeEvent.target.files,
                    datas = [];
                for (var i = 0; i < files.length; i++) {
                    readers[i] = new FileReader();
                    readers[i].onload = function (loadEvent) {
                        datas.push(loadEvent.target.result);
                        if (datas.length === files.length) {
                            scope.$apply(function () {
                                if (scope.fileread) {
                                    scope.fileread.file = $(element).val();
                                    scope.fileread.data = datas[0];
                                } else {
                                    scope.fileread = datas[0];
                                }
                            });
                        }
                    };
                    // readers[ i ].readAsDataURL( files[i] );
                    readers[i].readAsText(files[i]);
                }
            });
        },
    };
});

portal.directive("fileupload", function () {
    return {
        scope: {
            fileupload: "=",
        },
        link: function (scope, element, attributes) {
            element.bind("change", function (changeEvent) {
                var readers = [],
                    files = changeEvent.target.files,
                    datas = [];
                for (var i = 0; i < files.length; i++) {
                    readers[i] = new FileReader();
                    readers[i].onload = function (loadEvent) {
                        datas.push(loadEvent.target.result);
                        if (datas.length === files.length) {
                            scope.$apply(function () {
                                if (scope.fileupload) {
                                    scope.fileupload.file = $(element).val();
                                    //scope.fileupload.data = datas[0];
                                } else {
                                    //scope.fileupload = datas[0];
                                }
                            });
                        }
                    };
                    readers[i].readAsDataURL(files[i]);
                    //readers[ i ].readAsText( files[i] );
                }
            });
        },
    };
});

// daasauth
portal.directive("daasauth", function () {
    return {
        restrict: "E",
        templateUrl: "/partials/include/auth.form.html",
        scope: {
            allowCancel: "=allowcancel",
            allowPasswordRemind: "=allowpasswordremind",
            onsuccess: "=success",
            oncancel: "=cancel",
        },
        controller: [
            "$scope",
            "authService",
            "uiService",
            function ($scope, authService, uiService) {
                $scope.modeBase = true;
                $scope.modeTfa = false;
                $scope.modeRecovery = false;

                $scope.instance = { userName: "", password: "", authenticationCode: "", recoveryCode: "" };
                $scope.denyForgot = false;

                var onAuthenticated = function () {
                    if (authService.principal.claims.PASSWORD_EXPIRED) {
                        uiService.warning("Password expired and should be changed!");
                    } else if (authService.principal.claims.TFA_MISSCONFIGURED) {
                        uiService.warning("Two-factor authentication should be configured!");
                    } else if (authService.principal.claims.EULA_MISSCONFIGURED) {
                        uiService.warning("Eula must be accepted before you may continue to login!");
                    }

                    if ($scope.onsuccess) {
                        $scope.onsuccess();
                    }
                };

                $scope.signin = function () {
                    return authService.signin($scope.instance.userName, $scope.instance.password).then(
                        function () {
                            if (
                                authService.principal &&
                                authService.principal.claims.TFA_CONFIGURED &&
                                !authService.principal.claims.ROLE_AUTHORIZED
                            ) {
                                $scope.switchToTfa();
                            } else {
                                onAuthenticated();
                            }
                        },
                        function (err) {
                            err.errors = [{ description: err.message }];
                            $scope.denyForgot = err.code && err.code === "AUTH_LOCKED";
                            uiService.showValidation($scope)(err);
                        }
                    );
                };

                $scope.switchToTfa = function () {
                    $scope.modeBase = false;
                    $scope.modeTfa = true;
                    $scope.modeRecovery = false;
                };

                $scope.switchToRecovery = function () {
                    $scope.modeBase = false;
                    $scope.modeTfa = false;
                    $scope.modeRecovery = true;
                };

                $scope.verify = function () {
                    return authService.otpAuth($scope.instance.authenticationCode).then(
                        function () {
                            onAuthenticated();
                        },
                        function (err) {
                            err.errors = [{ description: err.message }];
                            uiService.showValidation($scope)(err);
                        }
                    );
                };

                $scope.recovery = function () {
                    return authService.otpRecoveryAuth($scope.instance.recoveryCode).then(
                        function () {
                            if ($scope.onsuccess) {
                                $scope.onsuccess();
                            }
                        },
                        function (err) {
                            err.errors = [{ description: err.message }];
                            uiService.showValidation($scope)(err);
                        }
                    );
                };

                $scope.cancel = function () {
                    if ($scope.oncancel) {
                        $scope.oncancel();
                    }
                };
            },
        ],
    };
});

// partner select
portal.directive("_daaspartnerselect", function () {
    return {
        restrict: "E",
        templateUrl: "/partials/include/partner.select.html",
        scope: {
            partnerId: "=",
            currentPartnerId: "=",
            onChange: "&",
            disabled: "=",
            name: "@",
            required: "=",
        },
        controller: [
            "$scope",
            "coreService",
            function ($scope, core) {
                $scope.partners = undefined;

                if (typeof $scope.partnerId === "undefined") {
                    $scope.partnerId = null;
                }

                $scope.$watch("partnerId", function (newVal, oldVal) {
                    if (newVal !== oldVal && typeof $scope.onChange === "function") {
                        $scope.onChange();
                    }
                });

                $scope.$watch("currentPartnerId", function (value) {
                    if (value) {
                        // reload if currentPartnerId is set
                        _loadPartners();
                    }
                });

                var _loadPartners = function () {
                    return core.partner.treeIndent($scope.currentPartnerId).then(function (p) {
                        $scope.partners = p;
                    });
                };

                (function () {
                    _loadPartners();
                })();
            },
        ],
    };
});

// partner select
portal.directive("daaspartnerselect", function () {
    return {
        restrict: "E",
        templateUrl: "/partials/include/partner.select.tree.html",
        scope: {
            partnerId: "=",
            currentPartnerId: "=",
            allowNoPartner: "=?",
            onChange: "&",
            disabled: "=",
            name: "@",
            required: "=",
            fullHierarchy: "=?",
        },
        link: function link($scope, element, attrs) {
            $scope.selectedPartner = $scope.noPartnerText = $scope.allowNoPartner
                ? "<< Any Partner >>"
                : "<< Select Partner >>";

            var foundMatch = false;

            $scope.tree = $(element)
                .find("div.tree-root")
                .tree({
                    slide: false,
                    selectable: false,
                    autoOpen: true,
                    onCreateLi: function (node, $el) {
                        if (foundMatch && !node.openForMatch && !node.parent.matches) {
                            $el.addClass("hidden-node");
                        }

                        if (node.matches) {
                            $el.addClass("highlight-node");
                        }
                    },
                    /*dataUrl: function (parentNode) {
                 return {
                 url: '/api/partner/tree/full/' + ($scope.currentPartnerId ? $scope.currentPartnerId : 0),
                 method: 'GET'
                 };
                 }*/
                });

            $scope.tree.bind("tree.click", function (event) {
                var node = event.node;
                // console.log('tree.click', node);
                $scope.$apply(function () {
                    $scope.partnerId = node ? node.id : null;
                });
            });

            $(element).on("click", "#partner-active", function (event) {
                event.stopPropagation();
            });

            $("#search-partner").on("keyup", function (event) {
                event.stopPropagation();

                var searchPartner = $("#search-partner").val().toLowerCase();
                var tree = $scope.tree.tree("getTree");

                if (!searchPartner) {
                    foundMatch = false;
                    tree.iterate(function (node) {
                        node["openForMatch"] = false;
                        node["matches"] = false;
                        return true;
                    });

                    $(element).find("div.tree-root").tree("refresh");
                    return;
                }

                foundMatch = false;

                tree.iterate(function (node) {
                    var matches = node.name.toLowerCase().includes(searchPartner);
                    node["openForMatch"] = matches;
                    node["matches"] = matches;

                    if (matches) {
                        foundMatch = true;

                        if (node.isFolder()) {
                            node.is_open = true;
                        }

                        var parent = node.parent;
                        while (parent) {
                            parent["openForMatch"] = true;
                            parent.is_open = true;
                            parent = parent.parent;
                        }
                    }

                    return true;
                });

                $scope.tree.tree("refresh");
            });

            $(element).on("hidden.bs.dropdown", function () {
                $scope.openSearchField = false;
                $scope.$apply();
            });
        },
        controller: [
            "$scope",
            "coreService",
            function ($scope, core) {
                $scope.onlyActive = true;

                var loaded = false;
                $scope.openSearchField = false;

                if (typeof $scope.partnerId === "undefined") {
                    $scope.partnerId = null;
                }

                $scope.$watch("partnerId", function (newVal, oldVal) {
                    // console.log('partnerId watch', newVal, oldVal);
                    if (newVal !== oldVal) {
                        if (typeof $scope.onChange === "function") {
                            $scope.onChange();
                        }

                        if (loaded) {
                            var node = $scope.tree.tree("getNodeById", $scope.partnerId);
                            $scope.selectedPartner = node ? node.name : $scope.noPartnerText;
                        }
                    }
                });

                $scope.$watch("onlyActive", function (v) {
                    _loadPartners();
                });

                $scope.$watch("currentPartnerId", function (newVal, oldVal) {
                    if (newVal) {
                        // reload if currentPartnerId is set
                        _loadPartners();
                    }
                });

                var _loadPartners = function () {
                    if (core.auth.isClientAnyUser()) {
                        return;
                    }
                    return core.partner
                        .treeFull($scope.currentPartnerId, $scope.onlyActive, $scope.fullHierarchy)
                        .then(function (p) {
                            //console.log('_loadPartners ', p);
                            if ($scope.allowNoPartner) {
                                p.unshift({ id: null, label: $scope.noPartnerText, children: [] });
                            }
                            $scope.tree.tree("loadData", p);
                            loaded = true;
                            if ($scope.partnerId) {
                                var node = $scope.tree.tree("getNodeById", $scope.partnerId);
                                $scope.selectedPartner = node ? node.name : $scope.noPartnerText;
                            }
                        });
                };

                (function () {
                    _loadPartners();
                })();
            },
        ],
    };
});

portal.directive("focusOnShow", function ($timeout) {
    return {
        restrict: "A",
        link: function ($scope, $element, $attr) {
            if ($attr.ngShow) {
                $scope.$watch($attr.ngShow, function (newValue) {
                    if (newValue) {
                        $timeout(function () {
                            $element[0].focus();
                        }, 0);
                    }
                });
            }
        },
    };
});

portal.directive("focusMe", function ($timeout, $parse) {
    return {
        link: function (scope, element, attrs) {
            var model = $parse(attrs.focusMe);
            scope.$watch(model, function (value) {
                ///console.log('value=',value);
                if (value === true) {
                    $timeout(function () {
                        element[0].focus();
                    });
                }
            });
        },
    };
});

portal.directive("daassettings", function () {
    return {
        restrict: "E",
        transclude: true,
        templateUrl: "/partials/include/settings.html",
    };
});

portal.directive("daasUserInfo", function () {
    return {
        restrict: "A",
        scope: {
            daasUserInfo: "=",
        },
        link: function ($scope, element, attrs) {
            element.bind("click", function () {
                $scope.show();
            });
        },
        controller: [
            "$scope",
            "$uibModal",
            "coreService",
            function ($scope, $modal, core) {
                var userId = $scope.daasUserInfo;

                $scope.show = function () {
                    core.user.getReadonly(userId, true).then(function (u) {
                        u.RoleName = core.ui.getRoleName(u.UserRoleId);

                        $modal.open({
                            templateUrl: "/partials/include/user.list.info.html",
                            resolve: {
                                user: function () {
                                    return u;
                                },
                            },
                            controller: [
                                "$scope",
                                "$uibModalInstance",
                                "coreService",
                                "user",
                                function ($scope, $modalInstance, core, user) {
                                    $scope.user = user;

                                    $scope.close = function (r) {
                                        $modalInstance.close(r);
                                    };
                                },
                            ],
                        });
                    });
                };
            },
        ],
    };
});

portal.directive("daasInfo", function () {
    return {
        restrict: "E",
        template:
            '<i class="glyphicon glyphicon-info-sign" title="{{title}}" data-toggle="tooltip" data-placement="right" tooltip ></i>',
        scope: {
            title: "@",
        },
    };
});

portal.directive("daasHelpInline", function () {
    return {
        restrict: "E",
        template:
            '<div class="help-inline">{{::title}} <a href="https://developers.bluefin.com/shieldconex/docs/templates#data-setup-reference" target="_blank">Learn more</a></div>',
        scope: {
            title: "@",
            more: "@",
        },
    };
});

portal.directive("tooltip", function () {
    return {
        restrict: "A",
        link: function (scope, element, attrs) {
            element.hover(
                function () {
                    element.tooltip("show");
                },
                function () {
                    element.tooltip("hide");
                }
            );
        },
    };
});

portal.directive("daaserrorpage", function () {
    return {
        restrict: "E",
        templateUrl: "/partials/error.html",
        scope: {
            caption: "@",
            description: "@",
        },
    };
});

portal.directive("daascontentviewer", function () {
    /*
    var prettifyXml = function (xml) {
        // https://stackoverflow.com/questions/376373/pretty-printing-xml-with-javascript
        var xmlDoc = new DOMParser().parseFromString(xml, "application/xml");
        var xsltDoc = new DOMParser().parseFromString(
            [
                '<xsl:stylesheet xmlns:xsl="http://www.w3.org/1999/XSL/Transform">',
                '  <xsl:output omit-xml-declaration="yes" indent="yes"/>',
                '  <xsl:template match="node()|@*">',
                '    <xsl:copy><xsl:apply-templates select="node()|@*"/></xsl:copy>',
                "  </xsl:template>",
                "</xsl:stylesheet>",
            ].join("\n"),
            "application/xml"
        );

        var xsltProcessor = new XSLTProcessor();
        xsltProcessor.importStylesheet(xsltDoc);
        var resultDoc = xsltProcessor.transformToDocument(xmlDoc);
        var resultXml = new XMLSerializer().serializeToString(resultDoc);
        return resultXml;
    };
    */

    var prettifyXml = function (xml) {
        // https://stackoverflow.com/questions/376373/pretty-printing-xml-with-javascript
        var formatted = "",
            indent = "",
            tab = "  ";
        xml.split(/>\s*</).forEach(function (node) {
            if (node.match(/^\/\w/)) {
                // decrease indent by one 'tab'
                indent = indent.substring(tab.length);
            }
            formatted += indent + "<" + node + ">\n";
            if (node.match(/^<?\w[^>]*[^\/]$/)) {
                // increase indent
                indent += tab;
            }
        });

        return formatted.substring(1, formatted.length - 2);
    };

    return {
        restrict: "E",
        templateUrl: "/partials/include/contentviewer.html",
        scope: {
            id: "@",
            headers: "=",
            body: "@",
        },
        link: function (scope) {
            var body = scope.body || "";
            scope.bodyRaw = body.split("\n");
            scope.bodyPretty = undefined;

            if (!scope.bodyPretty) {
                var canBeJson = body.indexOf("{") >= 0;
                if (canBeJson) {
                    try {
                        var json = JSON.parse(body);
                        var formatted = JSON.stringify(json, undefined, 2);
                        scope.bodyPretty = formatted.split("\n");
                    } catch {
                        // not json
                    }
                }
            }

            if (!scope.bodyPretty) {
                var canBeXml = body.indexOf("<") >= 0;
                if (canBeXml) {
                    try {
                        var formatted = prettifyXml(body);
                        scope.bodyPretty = formatted.split("\n");
                    } catch {
                        // not xml
                    }
                }
            }

            if (!scope.bodyPretty) {
                scope.bodyPretty = scope.bodyRaw;
            }
        },
        controller: [
            "$scope",
            function ($scope) {
                $scope.toggle = function (elementId, classname) {
                    document.getElementById(elementId).classList.toggle(classname);
                };
            },
        ],
    };
});
