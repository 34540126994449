"use strict";
var portal = angular.module("portal");

// add X-Requested-With to handle ajax requests
portal.config(['$httpProvider', function ($httpProvider) {
    $httpProvider.defaults.headers.common["X-Requested-With"] = 'XMLHttpRequest';

    // disable client side caching for get requests
    if (!$httpProvider.defaults.headers.get) {
        $httpProvider.defaults.headers.get = {};
    }
    //disable IE ajax request caching
    $httpProvider.defaults.headers.get['If-Modified-Since'] = 'Mon, 26 Jul 1997 05:00:00 GMT';
    // extra
    $httpProvider.defaults.headers.get['Cache-Control'] = 'no-cache';
    $httpProvider.defaults.headers.get['Pragma'] = 'no-cache';

    $httpProvider.defaults.cache = false;
}]);

portal.config(['$httpProvider',function ($httpProvider) {
    $httpProvider.interceptors.push(["$q", "$location", "uiService", "$rootScope", function ($q, $location, uiService, $rootScope) {
        return {
            "request": function (config) {
                return config;
            },
            "response": function (response) {
                return response;
            },
            "responseError": function (rejection) {
                console.log("response error:", rejection);
                if(rejection.status === 401){
                    rejection.data.shown = true;
                    $rootScope.$broadcast('unauthorized', { source: 'httpProvider', data: rejection.data, method: rejection.config.method });
                }
                else if (rejection.data && !rejection.data.success) {
                    var message = rejection.data.message || rejection.statusText;
                    if(rejection.data.code === 'BS_CONCURENCY'){
                        message = 'The data you attempted to update was modified by another user. Reload and try again';
                    }
                    else if(rejection.data.code === 'PW_EXPIRED'){
                        // [as] we may have more than one request on one page but I don't know good way to cancel others
                        console.log('password has expired during ajax response');
                        $location.path('/expired');
                    }else if(rejection.data.code === 'TFA_MISSCONFIGURED'){
                        console.log('tfa missconfigured');
                        $location.path('/settings/security');
                    }
                    else if(rejection.data.code === 'EULA_MISSCONFIGURED'){
                        console.log('eula missconfigured');
                        $location.path('/settings/eula');
                    }

                    if (!rejection.config || !rejection.config.disableUiErrorHandling) {
                        uiService.error(message);
                        rejection.data.shown = true;
                    }
                }

                return $q.reject(rejection);
            }
        };
    }]);
}]);

portal.config(["$provide", function ($provide) {
    $provide.decorator("$exceptionHandler", function ($delegate, $injector) {
        return function (exception, cause) {
            var ui = $injector.get("uiService");
            ui.error(exception.message);
            $delegate(exception, cause);
        };
    });
}]);

/// GROWL
// http://janstevens.github.io/angular-growl-2/
portal.config(["growlProvider", function (growlProvider) {
    growlProvider.globalTimeToLive({success: 3000, error: 10000, warning: 5000, info: 5000});
    growlProvider.globalDisableCountDown(true);
    growlProvider.onlyUniqueMessages(true);
}]);

/// DEBUG
portal.config(["$logProvider", function ($logProvider) {
    $logProvider.debugEnabled(true);
}]);

/// LOADING
portal.config(['cfpLoadingBarProvider', function (cfpLoadingBarProvider) {
    cfpLoadingBarProvider.includeSpinner = false;
    cfpLoadingBarProvider.includeBar = true;
    cfpLoadingBarProvider.latencyThreshold = 0;
}]);

/// Q
portal.config(['$qProvider', function ($qProvider) {
    $qProvider.errorOnUnhandledRejections(false);
}]);

/**
 * AngularJS default filter with the following expression:
 * "person in people | filter: {name: $select.search, age: $select.search}"
 * performs a AND between 'name: $select.search' and 'age: $select.search'.
 * We want to perform a OR.
 */
portal.filter('propsFilter', function() {
    return function(items, props) {
        var out = [];

        if (angular.isArray(items)) {
            items.forEach(function(item) {
                var itemMatches = false;

                var keys = Object.keys(props);
                for (var i = 0; i < keys.length; i++) {
                    var prop = keys[i];
                    var text = props[prop].toLowerCase();
                    if (item[prop] && item[prop].toString().toLowerCase().indexOf(text) !== -1) {
                        itemMatches = true;
                        break;
                    }
                }

                if (itemMatches) {
                    out.push(item);
                }
            });
        } else {
            // Let the output be the input untouched
            out = items;
        }

        return out;
    };
});


portal.config(['ngIntlTelInputProvider', function (ngIntlTelInputProvider) {
    ngIntlTelInputProvider.set({initialCountry: 'us', utilsScript: '/js/vendors/intlTelInput/utils.js', separateDialCode: true });
}]);


portal.config(['$ocLazyLoadProvider', function (ocLazyLoadProvider) {
    ocLazyLoadProvider.config({
        modules: [{
            name: 'AuthenticatedUsersModule',
            files: window.modules.authorized // ['js/app.authorized.module.js']
        }]
    });
}]);

