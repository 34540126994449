"use strict";
var portal = angular.module("portal");

/*****************
 SERVICES
 ******************/

function AuthorizationError(options) {
    if ( typeof options === "string" ) {
        options = { description: options };
    } else if ( typeof options === "object" ) {
        // use options as is
    } else {
        options = {};
    }

    this.message = "Forbidden";
    this.description = options.description || "User authentication required.";
    this.targetUrl = options.targetUrl;
}

AuthorizationError.prototype = Object.create(Error.prototype);
AuthorizationError.prototype.constructor = AuthorizationError;

// todo [ds]: use dependecy injection instead of global object definition
window.AuthorizationError = AuthorizationError;

portal.factory("authService", ["$rootScope", "ROLES", "httpService", "$q", "$ocLazyLoad", function ($rootScope, ROLES, httpService, $q, $ocLazyLoad) {
    var auth = {principal: null};

    auth.init = function (user, csrfToken) {
        // initUser should be first
        auth.initUser(user, csrfToken);

        // it duplicates section from app.route
        // here it is used to broadcast authenticated-modules-loaded
        // after js files loading
        // authenticated-modules-loaded is used to attach menu items showing
        // correct visibility can be fatched after when business module totally loaded
        return auth.initApp();
    };

    auth.initUser = function (user, csrfToken) {
        console.log("initializing principal", user);

        // map claims
        if(user && user.claims) {
            user.claims.BUSINESS_ACTIVITY = true;
            user.claims.CREDENTIALS_ACTIVITY = true;

            if(
                user.claims.TFA_MISSCONFIGURED
                || user.claims.PASSWORD_EXPIRED
                || user.claims.EULA_MISSCONFIGURED) {
                user.claims.BUSINESS_ACTIVITY = false;
            }

            if(user.claims.EULA_MISSCONFIGURED) {
                user.claims.CREDENTIALS_ACTIVITY = false;
            }

            // isTfaWarningShouldBeShown
            if(
                user.claims.TFA_ENFORCED
                && !user.claims.TFA_CONFIGURED
                && !user.claims.TFA_MISSCONFIGURED
            ) {
                user.claims.TFA_CONFIGURATION_WARNING = true;
            }
        }

        auth.principal = user;
        $rootScope.principal = user;

        if(csrfToken) {
            httpService.updateCsrfToken(csrfToken);
        }

        httpService.updateUserTimezone();

        $rootScope.$broadcast('principal-updated', {
            principal: $rootScope.principal
        });
    };

    auth.mapClaimsToRoute = function(defaultPath) {
        var path = defaultPath;
        if(auth.principal && auth.principal.claims) {
            path = path || auth.principal.claims.LANDING_PAGE;

            if (auth.principal.claims.PASSWORD_EXPIRED) {
                path = "/expired";
            }
            else if (auth.principal.claims.TFA_MISSCONFIGURED) {
                path = "/settings/security";
            }
            else if (auth.principal.claims.EULA_MISSCONFIGURED) {
                path = "/settings/eula";
            }
        }
        return path;
    };

    auth.initApp = function(force){
        var promise = $q.resolve();
        if(force || auth.isAuthenticated()) {
            promise = $ocLazyLoad.load(['AuthenticatedUsersModule'], {cache: true})
                .then(function () {
                    $rootScope.$broadcast('authenticated-modules-loaded', {});
                });
        }

        return promise;
    };

    auth.isAuthenticated = function () {
        return typeof auth.principal !== "undefined" && auth.principal !== null;
    };

    auth.demandRole = function (role) {
        return auth.isAuthenticated() && auth.principal.roleId === role;
    };

    auth.signout = function () {
        delete auth.principal;
        delete $rootScope.principal;
    };

    auth.activate = function(){
        return httpService.get("/csrf")
            .then(function(res){
                httpService.updateCsrfToken(res.csrfToken);
            });
    };

    auth.signin = function(userName, password) {
        var credentials = {
            userName: userName,
            password: password
        };
        return httpService.post("/login", credentials)
            .then(function(res){
                return auth.init(res.user);
            });
    };

    auth.saml = function() {
        return httpService.get("/saml")
            .then(function(res){
                return auth.init(res.user);
            });
    };

    auth.ack = function(context){
        return $q.when([])
        .then(function() {
            if(!auth.isAuthenticated()){
                throw new AuthorizationError({ targetUrl: context.targetUrl });
            }
        })
        .then(function(){
            return httpService.get("/api/user/status");
        })
        .then(function(info){
            if(!info.authorized) {
                throw new AuthorizationError({ targetUrl: context.targetUrl });
            }
        });
    };

    auth.otpAuth = function(code){
        var data = {
            code: code
        };
        return httpService.post("/otp_login", data)
            .then(function(res){
                return auth.init(res.user);
            });
    };

    auth.otpRecoveryAuth = function(code){
        var data = {
            code: code
        };
        return httpService.post("/otp_recovery_login", data)
            .then(function(res){
                return auth.init(res.user);
            });
    };

    // [as] avoid using this method as public
    auth._hasRole = function (roles) {
        if (!auth.isAuthenticated()) {
            return false;
        }
        if (typeof roles === "number") {
            roles = [roles];
        }
        return _.includes(roles, auth.principal.roleId);
    };

    auth.isSystemAdmin = function () {
        return auth.demandRole(ROLES.systemAdmin);
    };

    auth.isSystemUser = function () {
        return auth.demandRole(ROLES.systemUser);
    };

    auth.isPartnerUser = function () {
        return auth.demandRole(ROLES.partnerUser);
    };

    auth.isPartnerSupervisor = function () {
        return auth.demandRole(ROLES.partnerSupervisor);
    };

    auth.isPartnerAnyUser = function () {
        return auth._hasRole([ROLES.partnerUser, ROLES.partnerSupervisor]);
    };

    auth.isRootPartnerUser =  function () {
        return  $q.when(false);
    };

    auth.isClientAdmin = function () {
        return auth.demandRole(ROLES.clientAdmin);
    };

    auth.isClientUser = function () {
        return auth.demandRole(ROLES.clientUser);
    };

    auth.isSystemAnyUser = function () {
        return auth._hasRole([ROLES.systemAdmin, ROLES.systemUser]);
    };

    auth.isClientAnyUser = function () {
        return auth._hasRole([ROLES.clientAdmin, ROLES.clientUser]);
    };

    auth.isNotClientAnyUser = function () {
        return auth._hasRole([ROLES.systemAdmin, ROLES.systemUser, ROLES.partnerUser, ROLES.partnerSupervisor]);
    };

    auth.clientRoles = [ROLES.clientAdmin, ROLES.clientUser];

    return auth;
}]);

portal.factory("httpService", ["$http", "$q", function ($http, $q) {
    // we can return promise from $http but in this case we have to deals with parameters returns by it - which seems to me more confusing
    var http = { };

    http.updateCsrfToken = function(token){
        $http.defaults.headers.common['X-Csrf-Token'] = token;
        $.ajaxSetup({
            headers: { 'X-Csrf-Token': token }
        });
    };

    http.updateUserTimezone = function() {
        try {
            var offset = -new Date().getTimezoneOffset();
            var hours = Math.round(offset / 60);
            var minutes = offset % 60 === 0 ? ":00": ":30";
            var hour = Math.abs(hours) < 10 ? "0" + Math.abs(hours) : Math.abs(hours);
            hour = hours < 0 ? "-" + hour : "+" + hour;
            var timezone = hour + minutes;
           
            $http.defaults.headers.common['X-User-Timezone'] = timezone;
            $.ajaxSetup({
                headers: { 'X-User-Timezone': timezone }
            });
        } catch(e) {
            // do not set unknown timezone, ignore error
            console.warn(e)
        }
    }

    var done = function(res){
        var data = res && res.data ? res.data : res;
        return data;
    };

    var error = function(err){
        var data = err && err.data ? err.data : err;
        throw data;
    };

    http.get = function (url) {
        return $http.get(url).then(done, error);
    };
    http.post = function (url, data, ext) {
        return $http.post(url, data, ext).then(done, error);
    };
    http.patch = function (url, data, ext) {
        return $http.patch(url, data, ext).then(done, error);
    };
    http.put = function (url, data, ext) {
        return $http.put(url, data, ext).then(done, error);
    };
    http.delete = function (url) {
        return $http.delete(url).then(done, error);
    };
    http.patch = function (url, data, ext) {
        return $http.patch(url, data, ext).then(done, error);
    };

    return http;
}]);

portal.factory("uiService", ["growl", "ROLES", "moment", "$q", "$injector",
    function (growl, ROLES, moment, $q, $injector) {
        var ui = {};

        ui.success = function (msg) {
            growl.success(msg);
        };

        ui.error = function (msg) {
            growl.error(msg, 'error');
        };

        ui.warning = function (msg) {
            growl.warning(msg, 'warning');
        };

        ui.showResult = function (r, smsg, cbSucceed, cbFailed, fmsg) {
            //console.log("show result: ", r);
            r = r || {};
            if (r.success) {
                ui.success(smsg || r.message || "Action has been completed");
                if (cbSucceed) {
                    cbSucceed(r);
                }
            } else {
                ui.error(fmsg || r.message);
                if (cbFailed) {
                    cbFailed();
                }
            }
        };

        ui.showValidation = function ($scope) {
            return function (err) {
                console.error(err);
                var errors = $scope.errors = $scope.errors || [];

                errors.splice(0,errors.length);
                var newErrors = err.errors || (err.message ? [{ description: err.message }] : null);
                for(var idx in newErrors){
                    errors.push(newErrors[idx]);
                }

                if (!err.shown) {
                    ui.error(err.message || "Validation error(s)");
                }
            };
        };

        ui.clearErrors = function ($scope) {
            return function (r) {
                $scope.errors = [];
                return r;
            };
        };

        var ajaxErrorToObject = function(xhr, opts, thrown){
            var err = { message: null };
            try {
                err = JSON.parse(xhr.responseText);
                err.message = err.message || xhr.statusText;
            } catch (e) {
                err.message = xhr.statusText;
            }

            return err;
        };

        var showAjaxErrorFactory = function(defaultCallback, unauthorizedCallback){
            return function(xhr, opts, thrown) {
                var err = ajaxErrorToObject(xhr, opts, thrown);
                if (xhr.status === 401) {
                    unauthorizedCallback(err);
                }
                else {
                    defaultCallback(err);
                }
            };
        };

        var showAjaxErrorUnauthorizedFactory = function(method, rootScopeResolver, map){
            return showAjaxErrorFactory(function(err){
                ui.error(err.message);
            }, function(err){
                var opts = {source: 'ajax', method: method, data: err};
                opts = map ? map(opts) : opts;
                var $rootScope = rootScopeResolver();
                $rootScope.$broadcast('unauthorized', opts);
            });
        };

        ui.showAjaxGridErrorFactory = function(method, $rootScope, gridResolve){
            return showAjaxErrorUnauthorizedFactory(method, function(){
                return $rootScope;
            }, function (opts){
                var grid = gridResolve();
                return _.extend(opts, { source: 'grid', grid: grid });
            });
        };

        /*
		// [ds]: unused method
        ui.showAjaxError = (function(){
            return showAjaxErrorUnauthorizedFactory(function(){
                var $body = angular.element(document.body);
                var $rootScope = $body.injector().get('$rootScope');
                return $rootScope;
            });
        })();
       */

        ui.formatUser = function(u){
            if(u) {
                return (u.FirstName !== null ? u.FirstName : '') + ' ' + (u.LastName !== null ? u.LastName : '');
            }
            return undefined;
        };

        // [as] this should come from db
        ui.getRoleName = function(userRoleId){
            var r = '';
            switch(userRoleId){
                case ROLES.systemAdmin:
                    r = 'System Admin';
                    break;
                case ROLES.systemUser:
                    r = 'System User';
                    break;
                case ROLES.partnerUser:
                    r = 'Partner User';
                    break;
                case ROLES.clientAdmin:
                    r = 'Client Admin';
                    break;
                case ROLES.clientUser:
                    r = 'Client User';
                    break;
                case ROLES.partnerSupervisor:
                    r = 'Partner Supervisor';
                    break;
            }
            return r;
        };

        ui.generateUnique = function(){
            var ticks = new Date().getTime() * 10000 + 621355968000000000;
            var random = Math.random();
            var value = ticks + '' + random;
            return value;
        };

        ui.generateHash = function(value){
            return md5(value);
        };

        ui.generateHmacKey = function(){
            var key = ui.generateUnique();
            var keymd5 = ui.generateHash(key);
            var base64 = btoa(keymd5);
            return base64;
        };

        ui.getDate = function(d, useUtc){
            var res = null;
            if(d){
                var m = moment(d);
                if(useUtc){
                    var offset = d.getTimezoneOffset();
                    m = m.clone().zone(0).add(-1 * offset, 'minutes');
                }
                res = m.format("YYYY-MM-DDTHH:mm:ssZ");
            }
            return res;
        };

        ui.csvToArray = function(csvString, csvHeaderTemplate, createObjectFunc){
            var lineSeparator     = /\n/;
            var valueSeparators   = [","];

            var csvArray   = [];
            var csvRows    = csvString.split(lineSeparator);
            if(csvRows.length > 0) {
                var hasHeaderRow = _.some(csvHeaderTemplate, function(h) { return csvRows[0].indexOf(h) >= 0; });
                var valueSeparator = _.find(valueSeparators, function(s) { return csvRows[0].indexOf(s) > 0; });
                var csvHeaders = hasHeaderRow ? _.map(csvRows[0].split(valueSeparator), function(v){ return v.replace(/^"|"$| /g,'').trim().toLowerCase(); }) : csvHeaderTemplate;

                for(var rowIndex = hasHeaderRow ? 1 : 0; rowIndex < csvRows.length; ++rowIndex) {
                    var rowArray  = csvRows[rowIndex].split(valueSeparator);
                    if(rowArray.length === 1 && rowArray[0].trim() === ""){
                        continue;
                    }

                    var rowObject = createObjectFunc();
                    rowObject.Key = rowIndex;
                    csvArray.push(rowObject);

                    for(var propIndex = 0; propIndex < rowArray.length; ++propIndex) {
                        var propValue = rowArray[propIndex].replace(/^"|"$/g,'').trim();
                        var incomingHeader = csvHeaders[propIndex];
                        var propLabel = _.find(csvHeaderTemplate, function(th) { return th.toLowerCase() === incomingHeader; }) || incomingHeader;
                        if(propValue !== "") {
                            rowObject[propLabel] = propValue;
                        }
                    }

                    rowObject.init();
                }
            }

            return csvArray;
        };

        ui.showConfirmDialog = function (title, message, yesLabel, noLable, hideNoButton) {
            // if use injection in service have circular reference
            var $modal = $injector.get('$uibModal');
            var defer = $q.defer();
            var modalInstance = $modal.open({
                templateUrl: '/partials/include/confirm.dialog.html',
                controller: ["$scope", function ($scope) {
                    $scope.title = title;
                    $scope.message = message;
                    $scope.yesLabel = yesLabel || 'Yes';
                    $scope.noLable = noLable || 'No';
                    $scope.hideNoButton = hideNoButton;

                    $scope.ok = function () {
                        modalInstance.close();
                        defer.resolve();
                    };

                    $scope.cancel = function () {
                        modalInstance.dismiss();
                        defer.reject();
                    };
                }]
            });

            return defer.promise;
        };

        ui.modal = function (title, message) {
            // if use injection in service have circular reference
            var $modal = $injector.get('$uibModal');
            var defer = $q.defer();
            var modalInstance = $modal.open({
                templateUrl: '/partials/include/modal.dialog.html',
                controller: ["$scope", function ($scope) {
                    $scope.title = title;
                    $scope.message = message;

                    $scope.cancel = function () {
                        modalInstance.dismiss();
                        defer.reject();
                    };
                }]
            });

            return defer.promise;
        };

        ui.showLoading = function(element){
            $(element).LoadingOverlay("show", {zIndex: 9998});
        };

        ui.hideLoading = function(element){
            $(element).LoadingOverlay("hide", true);
        };

        return ui;
    }]);

portal.factory("dtService", ["$compile",
    function ($compile) {
        var dt = {};

        dt.renderIcon = function (icon, title, link) {
            return "<a class='glyphicon glyphicon-" + icon + "' href='" + link + "' title='" + title + "'></a>";
        };

        dt.renderViewEdit = function (data, detailsLink, mode) {
            mode = mode || 'edit'
            return mode === 'edit' ? dt.renderEdit(data, detailsLink) : dt.renderView(data, detailsLink);
        };

        dt.renderEdit = function (data, link) {
            return dt.renderDetails(data, link, { icon: 'pencil', title: 'Edit' });
        };

        dt.renderView = function (data, link) {
            return dt.renderDetails(data, link, { icon: 'eye-open', title: 'View' });
        };

        dt.renderDetails = function (data, detailsLink, options) {
            var link = detailsLink + '/' + data;

            options = options || {}
            var icon = options.icon || 'list-alt';
            var title = options.title || 'Show';

            return dt.renderIcon(icon, title, link);
        };

        var formattedDate = function(d, format){
            var str = "";
            if (d) {
                str = moment.utc(d).local().format(format);
            }
            return str;
        };

        dt.renderDate = function (d) {
            return formattedDate(d,'L LT');
        };

        dt.renderDateWithSeconds = function (d) {
            return formattedDate(d,'MM/DD/YYYY hh:mm:ss A');
        };

        dt.renderLongText = function (maxLength) {
            return function (m) {
                return m && m.length > maxLength ?
                    '<span title="' + m + '">' + m.substring(0, maxLength) + '...</span>' :
                    m;
            };
        };

        dt.renderDateWithoutTime = function (d) {
            return formattedDate(d,'L');
        };

        dt.renderUser = function (user) {
            return (user.FirstName || '') + ' ' + (user.LastName || '');
        };

        dt.renderUserEx = function ( data, type, full, meta ) {
            return dt.renderUser(full);
        };

        dt.renderUserWithDetails = function ( data, type, full, meta ) {
            var info = dt.renderUser(full);
            return "<a class='user-info-details' href='#' daas-user-info='" + data + "'>" + info + "</a>";
        };

        dt.renderYesNoFromBool = function (b) {
            return b ? "Yes" : "No";
        };

        dt.renderImageData = function (image) {
            if (image) {
                var i = JSON.parse(image.replace(/&quot;/g, '"'));
                return "<a href='" + i.ImageUrl + "' target='_blank'><i class='glyphicon glyphicon-picture'></i></a>";
            }
            else {
                return "-";
            }
        };

        dt.renderPartnerPath = function (path, showVirtualSign) {
            return function( data, type, full, meta){
                var pat = full[path];
                return data + '<br/><small class="' + (showVirtualSign && full.IsVirtual ? 'device-virtual': '') + '">' + pat + '</small>';
            };
        };

        dt.checkRowCallback = function ($scope) {
            return function (nRow, aData) {
                var $td = $('td', nRow);
                $compile($td)($scope);
                $td.bind('click', function (event) {
                    var $checkbox = $('#chk' + aData.Id);
                    if (event.target !== $checkbox[0]) {
                        $checkbox.click();
                    }
                });
                return nRow;
            };
        };

        dt.refresh = function(id, items){
            // escape manually
            var val = JSON.stringify(items);
            val = val.replace(/</g, '&lt;').replace(/>/g, '&gt;');
            var d = JSON.parse(val);

            var dt = $(id).DataTable().clear();
            if(d && d.length > 0){
                dt.rows.add(d);
            }
            dt.draw();
        };

        return dt;
    }]);

portal.factory("storageService", function () {

    var storage = { };

    storage.setShowNotifications = function (value) {
        sessionStorage.setItem('showNotifications', value.toString());
    };

    storage.getShowNotifications = function () {
        return (sessionStorage.getItem('showNotifications')  === 'false' ? false : true);
    };

    storage.setShowProductCommunication = function (value) {
        sessionStorage.setItem('showProductCommunication', value.toString());
    };

    storage.getShowProductCommunication = function () {
        return (sessionStorage.getItem('showProductCommunication')  === 'false' ? false : true);
    };

    return storage;
});

