"use strict";
var portal = angular.module("portal");

portal.config([
    "$routeProvider",
    "$locationProvider",
    "ROLES",
    function ($routeProvider, $locationProvider, ROLES) {
        $routeProvider
            .when("/login", {
                templateUrl: "/partials/login.html",
                controller: "LoginCtrl",
                anonymous: true,
            })
            // dashboard
            .when("/", {
                templateUrl: "/partials/dashboard/dashboard.html",
                controller: "DashboardCtrl",
            })
            .when("/contact", {
                templateUrl: "/partials/dashboard/contact.html",
            })
            /// manage
            .when("/manage/users", {
                templateUrl: "/partials/manage/users.html",
                roles: [
                    ROLES.systemAdmin,
                    ROLES.systemUser,
                    ROLES.partnerUser,
                    ROLES.partnerSupervisor,
                    ROLES.clientAdmin,
                ],
            })
            .when("/manage/users/:id", {
                templateUrl: "/partials/manage/user.html",
                roles: [
                    ROLES.systemAdmin,
                    ROLES.systemUser,
                    ROLES.partnerUser,
                    ROLES.partnerSupervisor,
                    ROLES.clientAdmin,
                ],
            })
            .when("/manage/partners", {
                templateUrl: "/partials/manage/partners.html",
                roles: [ROLES.systemAdmin, ROLES.systemUser, ROLES.partnerUser, ROLES.partnerSupervisor],
            })
            .when("/manage/partners/:id", {
                templateUrl: "/partials/manage/partner.html",
                roles: [ROLES.systemAdmin, ROLES.systemUser, ROLES.partnerUser, ROLES.partnerSupervisor],
            })
            .when("/manage/clients", {
                templateUrl: "/partials/manage/clients.html",
                roles: [ROLES.systemAdmin, ROLES.systemUser, ROLES.partnerUser, ROLES.partnerSupervisor],
            })
            .when("/manage/clients/:id", {
                templateUrl: "/partials/manage/client.html",
                roles: [ROLES.systemAdmin, ROLES.systemUser, ROLES.partnerUser, ROLES.partnerSupervisor],
            })
            .when("/manage/injectKeys", {
                templateUrl: "/partials/manage/injectKeys.html",
                controller: "InjectKeyListCtrl",
                roles: [ROLES.systemAdmin],
            })
            .when("/manage/injectKeys/:id", {
                templateUrl: "/partials/manage/injectKey.html",
                controller: "InjectKeyDetailsCtrl",
                roles: [ROLES.systemAdmin],
            })
            .when("/manage/templates", {
                templateUrl: "/partials/template/list.html",
                controller: "TemplateListCtrl",
                roles: [
                    ROLES.systemAdmin,
                    ROLES.systemUser,
                    ROLES.partnerUser,
                    ROLES.partnerSupervisor,
                    ROLES.clientAdmin,
                ],
            })
            .when("/manage/templates/:id/:type?", {
                templateUrl: "/partials/template/details.html",
                controller: "TemplateDetailsCtrl",
                roles: [
                    ROLES.systemAdmin,
                    ROLES.systemUser,
                    ROLES.partnerUser,
                    ROLES.partnerSupervisor,
                    ROLES.clientAdmin,
                ],
            })
            .when("/manage/template-sharing", {
                templateUrl: "/partials/templatesharing/list.html",
                controller: "TemplateSharingCtrl",
                roles: [ROLES.systemAdmin],
            })
            .when("/manage/systemnotifications", {
                templateUrl: "/partials/manage/systemnotifications.html",
            })
            .when("/saml/samlconfiguration", {
                templateUrl: "/partials/saml/samlconfiguration.html",
            })
            .when("/saml/samlconfiguration/:id", {
                templateUrl: "/partials/saml/samlconfigurationDetails.html",
                roles: [ROLES.systemAdmin, ROLES.systemUser],
            })
            // emails
            .when("/manage/emails", {
                templateUrl: "/partials/manage/emails.html",
                roles: [ROLES.systemAdmin, ROLES.systemUser],
            })
            .when("/manage/emails/:id", {
                templateUrl: "/partials/manage/email.html",
                roles: [ROLES.systemAdmin, ROLES.systemUser],
            })
            .when("/manage/proxy-configurations", {
                templateUrl: "/partials/gateway-template/list.html",
                controller: "GatewayTemplateListCtrl",
                roles: [ROLES.systemAdmin],
            })
            .when("/manage/proxy-configurations/:id", {
                templateUrl: "/partials/gateway-template/details.html",
                controller: "GatewayTemplateDetailsCtrl",
                roles: [ROLES.systemAdmin],
            })
            .when("/manage/branding", {
                templateUrl: "/partials/branding/list.html",
                roles: [ROLES.systemAdmin, ROLES.systemUser],
            })
            .when("/manage/branding/:id", {
                templateUrl: "/partials/branding/details.html",
                roles: [ROLES.systemAdmin, ROLES.systemUser],
            })
            /*
        when("/manage/maintenance/client", {
            templateUrl: "/partials/maintenance/client.import.html",
            roles: [ROLES.systemAdmin, ROLES.systemUser, ROLES.partnerUser, ROLES.partnerSupervisor]
        }).
        */

            .when("/manage/documentstore/:type", {
                templateUrl: "/partials/documentstore/list.html",
                controller: "DocumentStoreListCtrl",
                roles: [ROLES.systemAdmin],
            })
            .when("/manage/documentstore/:type?/:id", {
                templateUrl: "/partials/documentstore/details.html",
                controller: "DocumentStoreEditCtrl",
                roles: [ROLES.systemAdmin],
            })
            // audit
            .when("/manage/eventLogging", {
                templateUrl: "/partials/audit/list.html",
                controller: "AuditListCtrl",
                roles: [ROLES.systemAdmin, ROLES.systemUser, ROLES.partnerSupervisor],
            })
            .when("/manage/eventLogging/:id", {
                templateUrl: "/partials/audit/details.html",
                controller: "AuditDetailsCtrl",
                roles: [ROLES.systemAdmin, ROLES.systemUser, ROLES.partnerSupervisor],
            })
            // notifications management
            .when("/manage/notificationManagement", {
                templateUrl: "/partials/manage/notificationList.html",
                roles: [ROLES.systemAdmin],
            })
            .when("/manage/notificationManagement/:id", {
                templateUrl: "/partials/manage/notificationDetails.html",
                roles: [ROLES.systemAdmin],
            })
            .when("/manage/systemConfig", {
                templateUrl: "/partials/systemConfig/systemConfig.html",
                controller: "SystemConfigurationCtrl",
                roles: [ROLES.systemAdmin, ROLES.systemUser],
            })
            /// profile
            .when("/settings/profile", {
                templateUrl: "/partials/settings/profile.html",
                controller: "ProfileCtrl",
            })
            .when("/settings/account", {
                templateUrl: "/partials/settings/account.html",
                controller: "SettingsAccountCtrl",
            })
            .when("/settings/security", {
                templateUrl: "/partials/settings/security.html",
                controller: "SettingsSecurityCtrl",
            })
            .when("/settings/two_factor_authentication/app", {
                templateUrl: "/partials/settings/tfa_app.html",
                controller: "SettingsSecurityTfaAppCtrl",
            })
            .when("/settings/two_factor_authentication/verify", {
                templateUrl: "/partials/settings/tfa_verify.html",
                controller: "SettingsSecurityTfaVerifyCtrl",
            })
            .when("/settings/two_factor_authentication/configure", {
                templateUrl: "/partials/settings/tfa_configure.html",
                controller: "SettingsSecurityTfaConfigureCtrl",
            })
            .when("/settings/two_factor_authentication/recovery-codes", {
                templateUrl: "/partials/settings/tfa_recovery_codes.html",
                controller: "SettingsSecurityTfaRecoveryCodesCtrl",
            })
            .when("/settings/eula", {
                templateUrl: "/partials/settings/eula.html",
                controller: "SettingsEulaCtrl",
                allowMissconfiguredEula: true,
            })
            .when("/forgot", {
                templateUrl: "/partials/settings/forgot.html",
                controller: "ForgotCtrl",
                anonymous: true,
            })
            .when("/expired", {
                templateUrl: "/partials/settings/expired.html",
                controller: "ExpiredCtrl",
            })
            /// transaction
            .when("/transactions", {
                templateUrl: "/partials/transaction/transactions.html",
                roles: [
                    ROLES.systemAdmin,
                    ROLES.systemUser,
                    ROLES.partnerUser,
                    ROLES.partnerSupervisor,
                    ROLES.clientAdmin,
                    ROLES.clientUser,
                ],
            })
            /// gateway transaction
            .when("/proxy-logs", {
                templateUrl: "/partials/gateway-transaction/list.html",
                controller: "GatewayTransactionListCtrl",
                roles: [
                    ROLES.systemAdmin,
                    ROLES.systemUser,
                    ROLES.partnerUser,
                    ROLES.partnerSupervisor,
                    ROLES.clientAdmin,
                    ROLES.clientUser,
                ],
            })
            .when("/proxy-logs/:id", {
                templateUrl: "/partials/gateway-transaction/details.html",
                controller: "GatewayTransactionDetailsCtrl",
                roles: [
                    ROLES.systemAdmin,
                    ROLES.systemUser,
                    ROLES.partnerUser,
                    ROLES.partnerSupervisor,
                    ROLES.clientAdmin,
                    ROLES.clientUser,
                ],
            })
            /// virtual terminal
            .when("/virtualTerminal", {
                templateUrl: "/partials/virtualTerminal/virtualTerminal.html",
                roles: [
                    ROLES.systemAdmin,
                    ROLES.systemUser,
                    ROLES.partnerUser,
                    ROLES.partnerSupervisor,
                    ROLES.clientAdmin,
                    ROLES.clientUser,
                ],
                controller: "VirtualTerminalCtrl",
            })
            /// reports
            .when("/reports/partnerSummary", {
                templateUrl: "/partials/reports/partnerSummary.html",
                roles: [ROLES.systemAdmin, ROLES.systemUser, ROLES.partnerSupervisor, ROLES.partnerUser],
            })
            .when("/reports/clientSummary", {
                templateUrl: "/partials/reports/clientSummary.html",
                roles: [ROLES.systemAdmin, ROLES.systemUser, ROLES.partnerSupervisor, ROLES.partnerUser],
            })
            .when("/reports/transaction-summary-partner", {
                templateUrl: "/partials/reports/transactionSummary.Partner.html",
                roles: [ROLES.systemAdmin, ROLES.systemUser, ROLES.partnerUser, ROLES.partnerSupervisor],
            })
            .when("/reports/transaction-summary-client", {
                templateUrl: "/partials/reports/transactionSummary.Client.html",
                roles: [
                    ROLES.systemAdmin,
                    ROLES.systemUser,
                    ROLES.partnerUser,
                    ROLES.partnerSupervisor,
                    ROLES.clientAdmin,
                    ROLES.clientUser,
                ],
            })
            .when("/reports/eula", {
                templateUrl: "/partials/reports/eula.html",
                roles: [ROLES.systemAdmin],
            })
            .when("/reports/userReport", {
                templateUrl: "/partials/reports/userReport.html",
                roles: [
                    ROLES.systemAdmin,
                    ROLES.systemUser,
                    ROLES.partnerUser,
                    ROLES.partnerSupervisor,
                    ROLES.clientAdmin,
                    ROLES.clientUser,
                ],
            })
            .when("/reports/activityDevice", {
                templateUrl: "/partials/reports/deviceActivity.html",
                roles: [
                    ROLES.systemAdmin,
                    ROLES.systemUser,
                    ROLES.partnerUser,
                    ROLES.partnerSupervisor,
                    ROLES.clientAdmin,
                    ROLES.clientUser,
                ],
            })
            .when("/reports/tokenizationTotals", {
                templateUrl: "/partials/reports/tokenizationMetric.html",
                controller: "TokenizationMetricCtrl",
                roles: [
                    ROLES.systemAdmin,
                    ROLES.systemUser,
                    ROLES.partnerSupervisor,
                    ROLES.partnerUser,
                    ROLES.clientAdmin,
                    ROLES.clientUser,
                ],
            })
            .when("/reports/tokenizationUnclaimed", {
                templateUrl: "/partials/reports/tokenization.unclaimed.html",
                controller: "TokenizationUnclaimedReportCtrl",
                roles: [
                    ROLES.systemAdmin,
                    ROLES.systemUser,
                    ROLES.partnerSupervisor,
                    ROLES.partnerUser,
                    ROLES.clientAdmin,
                    ROLES.clientUser,
                ],
            })
            .when("/reports/tokenizationReferrer", {
                templateUrl: "/partials/reports/tokenization.referrer.html",
                controller: "TokenizationReferrerReportCtrl",
                roles: [
                    ROLES.systemAdmin,
                    ROLES.systemUser,
                    ROLES.partnerSupervisor,
                    ROLES.partnerUser,
                    ROLES.clientAdmin,
                    ROLES.clientUser,
                ],
            })
            .when("/reports/3dsDetails", {
                templateUrl: "/partials/reports/3ds.details.html",
                roles: [
                    ROLES.systemAdmin,
                    ROLES.systemUser,
                    ROLES.partnerSupervisor,
                    ROLES.partnerUser,
                    ROLES.clientAdmin,
                    ROLES.clientUser,
                ],
            })
            .when("/reports/3dsPartnerSummary", {
                templateUrl: "/partials/reports/3ds.partner-summary.html",
                roles: [
                    ROLES.systemAdmin,
                    ROLES.systemUser,
                    ROLES.partnerSupervisor,
                    ROLES.partnerUser,
                    ROLES.clientAdmin,
                    ROLES.clientUser,
                ],
            })
            .when("/reports/3dsClientSummary", {
                templateUrl: "/partials/reports/3ds.client-summary.html",
                roles: [
                    ROLES.systemAdmin,
                    ROLES.systemUser,
                    ROLES.partnerSupervisor,
                    ROLES.partnerUser,
                    ROLES.clientAdmin,
                    ROLES.clientUser,
                ],
            })
            // documentation
            .when("/documentation", {
                templateUrl: "/partials/documentation/documents.html",
            })
            /// errors
            .when("/401", {
                templateUrl: "/partials/401.html",
                anonymous: true,
                bodyClass: "error-page error-401",
            })
            .when("/403", {
                templateUrl: "/partials/403.html",
                bodyClass: "error-page error-403",
            })
            .otherwise({
                templateUrl: "/partials/404.html",
                bodyClass: "error-page error-404",
            });

        $locationProvider.html5Mode(true);
    },
]);

portal.run([
    "$rootScope",
    "$location",
    "authService",
    function ($rootScope, $location, authService) {
        $rootScope.$on("$routeChangeStart", function (event, next, prev) {
            var r = next.$$route;
            if (!r) {
                console.log("route not found");
                return;
            }

            if (!r.anonymous) {
                next.resolve = next.resolve || {};
                if (!next.resolve.authorizationResolver) {
                    next.resolve.moduleResolver = function () {
                        // section duplicates mostly the same section inside auth service
                        // here it is used to load modules before routing
                        // to support correct behavior on refresh
                        // // return $ocLazyLoad.load(['AuthenticatedUsersModule'], { cache: true });
                        return authService.initApp(true);
                    };

                    next.resolve.authorizationResolver = authService.ack.bind(authService, {
                        targetUrl: $location.$$url,
                    });
                }
            }

            if (r.roles && authService.principal && !_.includes(r.roles, authService.principal.roleId)) {
                console.log("user not authorized to see route", r.originalPath);
                $location.path("/403");
            }

            // [ds] did not find scenario where commented fragment should be used

            /*
            else if (authService.principal && authService.principal.claims.EULA_MISSCONFIGURED && !next.allowMissconfiguredEula) {
                event.preventDefault();
            }
            */

            /*
            else if(authService.passwordExpired && r.originalPath !== '/expired'){
                console.log('password has expired during navigation');
                $location.path('/expired');
            }
            */
        });

        $rootScope.$on("$routeChangeError", function (event, next, prev, error) {
            if (error instanceof AuthorizationError) {
                if (authService.principal && authService.principal.claims["SAML_AUTH_KEY"]) {
                    document.location.href =
                        window.location.origin + "/saml/" + authService.principal.claims["SAML_AUTH_KEY"];
                } else {
                    $location.path("/login").search("return_url", error.targetUrl);
                }
            }
        });

        $rootScope.$on("$routeChangeSuccess", function (event, current) {
            var bodyClass = current.bodyClass || "";
            document.getElementsByTagName("body").item(0).className = bodyClass;
        });
    },
]);
