"use strict";
var portal = angular.module("portal");

/*****************
 CONTROLLERS
 ******************/
portal.value("loginWindowState", {
    list: [],

    show: function (data, builder) {
        this.update(data);
        if (!this.isAlreadyShown()) {
            var self = this;
            builder().then(function () {
                self.clean();
            });
        }
    },

    update: function (data) {
        this.list.push(data);
    },

    isAlreadyShown: function () {
        return this.list.length > 1;
    },

    // all operations belong to grid loading
    isGridRepeatableOperation: function () {
        var accept = true;
        for (var i = 0; i < this.list.length; i++) {
            var data = this.list[i];
            accept = accept && data.source === "grid" && data.grid !== null;
        }

        return accept;
    },

    isManuallyRepeatableOperation: function () {
        // we are supposing 'get' method is possible
        // while form initialization,
        // so in case of 401 it is more safe
        // from business point of view to reload the page
        // while in case of 'put', 'post', 'delete' it is possible to just repeat an action
        // manually or automatically (in case of grid operation)
        return this.list.length === 1 && this.list[0].method && this.list[0].method.toLowerCase() !== "get";
    },

    reloadGrids: function () {
        for (var i = 0; i < this.list.length; i++) {
            var data = this.list[i];
            if (data.source === "grid") {
                data.grid.ajax.reload();
            }
        }
    },

    clean: function () {
        this.list = [];
    },
});
portal.controller("PageCtrl", [
    "$rootScope",
    "$scope",
    "authService",
    "$route",
    "$uibModal",
    "$location",
    "uiService",
    "loginWindowState",
    "$filter",
    "httpService",
    "userService",
    function ($rootScope, $scope, authService, $route, $modal, $location, uiService, loginWindowState, $filter, httpService, userService, coreService) {
        $scope.alerts = [];
        $scope.activeClass = "";
        $scope.isAuthModulesLoaded = false;

        $scope.$on("$routeChangeStart", function (next, current) {
            if (current.$$route) {
                $scope.activeClass = current.$$route.originalPath === "/" ? "active" : "";
            }
        });

        $scope.$on("principal-updated", function () {
            $scope.alerts = [];
            $scope.showTfaConfigurationWarningIfRequired();
            $scope.showEulaConfigurationWarningIfRequired();
            if ($scope.isAuth()) {
                $scope.getNotifications();
            } else {
                console.log("not auth");
            }
        });

        $scope.$on("authenticated-modules-loaded", function () {
            $scope.isAuthModulesLoaded = true;
        });

        $scope.getNotifications = function () {
            userService.notifications().then(function (n) {
                $scope.notifications = n;
                $rootScope.notificationCountNo = n.length;
            });
        };

        $scope.isHeaderAreaVisible = function () {
            var locationPath = $location.path();
            return locationPath !== "/login" && locationPath !== "/forgot" && $scope.isAuth() && $scope.isAuthModulesLoaded;
        };

        $scope.closeAlert = function (index) {
            $scope.alerts.splice(index, 1);
        };

        $scope.isAuth = function () {
            return authService.isAuthenticated();
        };

        $scope.init = function (user, csrfToken, config) {
            var u = JSON.parse(user);
            authService.init(u, csrfToken);

            uiService.appConfig = JSON.parse(config);
        };

        $scope.showTfaConfigurationWarningIfRequired = function () {
            if (authService.principal && authService.principal.claims && authService.principal.claims.TFA_CONFIGURATION_WARNING) {
                var alert = {
                    type: "warning",
                    msg: "<strong><a href='/settings/security'>Two-factor authentication</a></strong> should be configured, " + "you have to complete this task " + $filter("amTimeAgo")(authService.principal.claims.TFA_ENFORCED_FROM) + ".",
                };

                $scope.alerts.splice(0, $scope.alerts.length);
                $scope.alerts.push(alert);
            }
        };

        $scope.showEulaConfigurationWarningIfRequired = function () {
            if (authService.principal && authService.principal.claims && authService.principal.claims.EULA_MISSCONFIGURED) {
                var alert = {
                    type: "warning",
                    msg: "<strong><a href='/settings/eula'>Eula</a></strong> must be accepted before you may continue to login!",
                };

                $scope.alerts.splice(0, $scope.alerts.length);
                $scope.alerts.push(alert);
            }
        };

        $scope.requestCredentials = function () {
            var modalInstance = $modal.open({
                templateUrl: "/partials/include/auth.dialogue.html",
                controller: "LoginDialogueCtrl",
                size: "md",
            });

            return modalInstance.result;
        };

        $scope.$on("unauthorized", function (ev, data) {
            var prevRoleId = !_.isNil(authService.principal) ? authService.principal.roleId : undefined;
            loginWindowState.show(data, function () {
                return $scope.requestCredentials().then(
                    /*success*/
                    function () {
                        var currentRoleId = !_.isNil(authService.principal) ? authService.principal.roleId : undefined;
                        var isSameRole = prevRoleId && currentRoleId && prevRoleId === currentRoleId;
                        if (!isSameRole) {
                            console.log("role has been changed...");
                            $location.path("/");
                        } else if (loginWindowState.isGridRepeatableOperation()) {
                            loginWindowState.reloadGrids();
                        } else if (loginWindowState.isManuallyRepeatableOperation()) {
                            uiService.success("Successfully authenticated. Try to repeat last operation.");
                        } else {
                            var authorizedReloadEventData = { cancel: false };
                            $rootScope.$broadcast("authorizedReload", authorizedReloadEventData);
                            if (!authorizedReloadEventData.cancel) {
                                $route.reload();
                            }
                        }
                    },
                    /*cancel*/ function () {
                        authService.signout();
                        $location.path("/");
                    }
                );
            });
        });
    },
]);

portal.controller("LoginCtrl", [
    "$rootScope",
    "$scope",
    "authService",
    "uiService",
    "$location",
    function ($rootScope, $scope, authService, uiService, $location) {
        $scope.ready = false;
        $scope.returnUrl = $location.absUrl().search("code=") > 0 ? "/" : $location.search().return_url;

        authService.activate().then(function () {
            $scope.ready = true;
        });

        $scope.redirect = function () {
            var path = authService.mapClaimsToRoute($scope.returnUrl) || "/";
            $location.path(path).search("return_url", null);
        };
    },
]);

portal.controller("LoginDialogueCtrl", [
    "$scope",
    "$uibModalInstance",
    "$location",
    "authService",
    function ($scope, $modalInstance, $location, authService) {
        $scope.close = function () {
            $modalInstance.close();
            // redirect after closing dialogue if there are any claims which require additional action
            var path = authService.mapClaimsToRoute();
            if (path && path !== "/") {
                $location.path(path);
            }
        };

        $scope.cancel = function () {
            $modalInstance.dismiss("cancel");
        };
    },
]);

portal.controller("ForgotCtrl", [
    "$scope",
    "$rootScope",
    "$location",
    "userService",
    "uiService",
    "authService",
    "$routeParams",
    "jwtHelper",
    function ($scope, $rootScope, $location, userService, uiService, authService, $routeParams, jwtHelper) {
        $scope.instance = {
            userName: "",
            code: "",
            password: "",
            passwordconfirm: "",
        };

        $scope.isResetMode = false;
        $scope.ready = false;

        var token = $routeParams.code;
        if (!!token) {
            try {
                var tokenPayload = jwtHelper.decodeToken(token);
                if (jwtHelper.isTokenExpired(token)) {
                    throw new Error("Token expired");
                }

                $scope.instance.userName = tokenPayload.sub;
                $scope.instance.code = token;
                $scope.isResetMode = true;
            } catch (err) {
                $scope.errors = [{ description: "Invalid token. Try to request reset password again." }];
            }
        }

        authService.activate().then(function () {
            $scope.ready = true;
        });

        $scope.forgot = function () {
            userService.forgot($scope.instance.userName).then(function (r) {
                $scope.instance.userName = "";
                $scope.forgotPasswordForm.$setPristine();
                uiService.showResult(r, "Check email for further steps");
            }, uiService.showValidation($scope));
        };

        $scope.reset = function () {
            userService.restore($scope.instance.code, $scope.instance.password, $scope.instance.passwordconfirm).then(function (r) {
                uiService.showResult(r, "Password has been changed");
                $location.path("/");
            }, uiService.showValidation($scope));
        };

        $scope.close = function () {
            $location.path("/");
        };
    },
]);

portal.controller("ExpiredCtrl", [
    "$scope",
    "$rootScope",
    "$location",
    "userService",
    "uiService",
    "authService",
    function ($scope, $rootScope, $location, userService, uiService, authService) {
        $scope.pass = {};

        $scope.userName = authService.principal.UserName;

        // some routes doesn't have ajax requests - thus we have to handle this on client side
        authService.passwordExpired = true;

        $scope.reset = function () {
            userService.reset($scope.pass).then(function (r) {
                delete authService.passwordExpired;
                uiService.showResult(r, "Password has been reset");
                userService.logout().then(function () {
                    $location.path("/login");
                });
            }, uiService.showValidation($scope));
        };
    },
]);
